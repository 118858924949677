import React from 'react';
import { Link } from 'react-router-dom';

import { ThemeProvider, styled } from '@mui/material/styles';
import {
  CssBaseline,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import QueueIcon from '@mui/icons-material/Queue';

import { Theme } from '../../conf/theme';
import { useLogout } from '../../hooks/useLogout';

const SLink = styled(Link)({
  textDecoration: 'none',
  color: Theme.palette.text.primary,
});

type Props = {
  children: React.ReactNode;
  title: string;
  menuId: number;
};

const drawerWidth = 240;

export const Template: React.VFC<Props> = (props) => {
  const { children, title, menuId } = props;
  const { logout } = useLogout();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              logout();
            }}
          >
            ログアウト
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <SLink to="/accounts">
              <ListItem button sx={{ bgcolor: menuId === 0 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <SwitchAccountIcon />
                </ListItemIcon>
                <ListItemText primary="アカウント管理" />
              </ListItem>
            </SLink>
            <SLink to="/devices">
              <ListItem button sx={{ bgcolor: menuId === 1 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <PhonelinkSetupIcon />
                </ListItemIcon>
                <ListItemText primary="デバイス管理" />
              </ListItem>
            </SLink>
            <SLink to="/groups">
              <ListItem button sx={{ bgcolor: menuId === 2 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <AutoAwesomeMotionIcon />
                </ListItemIcon>
                <ListItemText primary="グループ管理" />
              </ListItem>
            </SLink>
            <SLink to="/taskqueues">
              <ListItem button sx={{ bgcolor: menuId === 3 ? '#eee' : '#fff' }}>
                <ListItemIcon>
                  <QueueIcon />
                </ListItemIcon>
                <ListItemText primary="キュー一覧" />
              </ListItem>
            </SLink>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Typography component="h2" variant="h5" color="inherit" noWrap>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
