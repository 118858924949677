import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  TextField,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, red } from '@mui/material/colors';

import { Template } from '../components/templates/Template';
import { TablePaginationActions } from '../components/utilities/TablePagination';
import { useAllAccounts } from '../hooks/useAllAccounts';
import { useCheckToken } from '../hooks/useCheckToken';
import { AccountType } from '../types/api/Accounts';
import { useDeleteAccount } from '../hooks/useDeleteAccount';

const searchAccount = (targetAccounts: AccountType[], word: string): AccountType[] => {
  if (word === '') {
    return targetAccounts;
  }

  return targetAccounts.filter((account) => account.name.toLowerCase().includes(word.toLowerCase()));
};

// React Component
export const AccountListPage: React.VFC = () => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const hisotry = useHistory();
  const [searchWord, setSearchWord] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const { getAccounts, accounts } = useAllAccounts();
  const { deleteAccount } = useDeleteAccount();
  const searchedAccounts = searchAccount(accounts, searchWord);

  const toAccountRegisterationPage = () => {
    hisotry.push('/accounts/new');
  };

  const toDetailPage = (id: string) => {
    hisotry.push(`/accounts/details/${id}`);
  };

  const accountDelete = async (id: string) => {
    const result = window.confirm('アカウントを削除してもよろしいですか？');
    if (!result) {
      return;
    }
    await deleteAccount(id);
    await getAccounts();
  };

  // Table Pagination ------------------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchedAccounts.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ---------------------------------------------------

  useEffect(() => {
    getAccounts();
  }, []);

  return (
    <Template title={'アカウント一覧'} menuId={0}>
      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2 }} onClick={toAccountRegisterationPage}>
          アカウント登録
        </Button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSearchWord(searchInput);
        }}
        style={{ marginBottom: '2rem', display: 'flex', alignItems: 'center' }}
      >
        <TextField
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          placeholder="アカウント名で検索"
          size="small"
          sx={{ width: '50ch' }}
        />
        <Button variant="contained" onClick={() => setSearchWord(searchInput)} sx={{ ml: 1 }}>
          検索
        </Button>
        {searchWord !== '' && <Box sx={{ ml: 3 }}>{`“${searchWord}”の検索結果 ${searchedAccounts.length}件`} </Box>}
      </form>

      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb' }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts table"
        >
          <TableHead>
            <TableRow>
              <TableCell>アカウント名</TableCell>
              <TableCell>顔管理システム</TableCell>
              <TableCell align="center">状態</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? searchedAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : searchedAccounts
            ).map((account) => (
              <TableRow key={account.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {account.name}
                </TableCell>
                <TableCell>
                  {account.face_manage_type === 1 ? 'KaoBank' : account.face_manage_type === 2 ? 'MyAnchor' : ''}
                </TableCell>

                <TableCell align="center">
                  {account.status === 1 ? (
                    <CheckCircleOutlineIcon sx={{ color: green[600] }} />
                  ) : (
                    <CancelOutlinedIcon sx={{ color: red[600] }} />
                  )}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    color="primary"
                    aria-label="settings"
                    component="span"
                    sx={{ mx: 2 }}
                    onClick={() => {
                      toDetailPage(account.id);
                    }}
                  >
                    <SettingsIcon sx={{ color: '#757575' }} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="delete"
                    component="span"
                    sx={{ mx: 1 }}
                    onClick={() => accountDelete(account.id)}
                  >
                    <DeleteIcon sx={{ color: '#757575' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={searchedAccounts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Template>
  );
};
