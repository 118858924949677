import React from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

export const useDeleteDevice = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const deleteDevice = (id: string) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .delete(`${ApiUrl}/admin/api/v1/devices/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { deleteDevice, loading, error };
};
