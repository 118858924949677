import React, { useState, memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

// import material UI components
import {
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { blueGrey } from '@mui/material/colors';

// custome
import { Template } from '../components/templates/Template';
import { TablePaginationActions } from '../components/utilities/TablePagination';
import { useAccountGroups } from '../hooks/useAccountGroups';
import { useAllAccounts } from '../hooks/useAllAccounts';
import { useDeleteGroup } from '../hooks/useDeleteGroup';
import { useCheckToken } from '../hooks/useCheckToken';

const Tag = styled.span({
  backgroundColor: blueGrey[400],
  marginRight: '0.75rem',
  padding: '0.35em 0.6em',
  color: 'white',
  borderRadius: '100px',
});

export const GroupIndex: React.VFC = memo(() => {
  const hisotry = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getAccounts, accounts, loading: deviceLoading, error: deviceError } = useAllAccounts();
  const {
    getAccountGroups,
    groups,
    notExists: groupNotExists,
    loading: groupLoading,
    error: groupError,
  } = useAccountGroups();
  const { deleteGroup, loading, error } = useDeleteGroup();
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0;

  const toGroupRegister = () => {
    hisotry.push('/groups/edit');
  };
  const toGroupEdit = (id: string) => {
    hisotry.push(`/groups/edit/${id}`);
  };

  const onClickDeleteGroup = (id: string, account_id: string) => {
    const result = window.confirm('グループを削除してもよろしいですか？');
    if (result) {
      deleteGroup(id);
      if (!error && !loading) {
        alert('グループを削除しました。');
        getAccountGroups(account_id); // 仮
      }
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChangeAccount = (event: any) => {
    getAccountGroups(event.target.value);
  };

  // ここでAPIをたたく
  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <Template title={'グループ一覧'} menuId={2}>
      <Box sx={{ width: '100%' }}>
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 1 }} onClick={toGroupRegister}>
            グループ作成
          </Button>
        </div>
      </Box>
      <Box maxWidth="sm" sx={{ pt: 5 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">アカウントを選択</InputLabel>
            <Select id="demo-simple-select" label="アカウントを選択" onChange={onChangeAccount}>
              {accounts.length > 0
                ? accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb', my: 5 }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts table"
        >
          <TableHead>
            <TableRow>
              <TableCell>グループ名</TableCell>
              <TableCell>デバイス</TableCell>
              <TableCell>メモ</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : groups).map(
              (group) => (
                <TableRow key={group.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>
                    {group.devices && group.devices.map((device) => <Tag key={device.id}>{device.name}</Tag>)}
                  </TableCell>
                  <TableCell>{group.memo}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      aria-label="edit"
                      component="span"
                      onClick={() => toGroupEdit(group.id)}
                    >
                      <SettingsIcon sx={{ color: '#757575' }} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      component="span"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        onClickDeleteGroup(group.id, group.account_id);
                      }}
                    >
                      <DeleteIcon sx={{ color: '#757575' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={groups.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Template>
  );
});
