import React, { useCallback } from 'react';

import { DeviceListType, ResDeviceListType } from '../types/api/Devices';

export const useFormattingDevices = (devices: Array<ResDeviceListType>) => {
  const [formattedDevices, setFormattedDevices] = React.useState<Array<DeviceListType>>([]);

  const deviceType = (type: number) => {
    let deviceTypeName = '';
    switch (type) {
      case 1:
        deviceTypeName = 'Edge Controller';
        break;
      case 2:
        deviceTypeName = 'Pad';
        break;
      case 3:
        deviceTypeName = 'AIBOX';
        break;
      case 4:
        deviceTypeName = 'thermo';
        break;
    }
    return deviceTypeName;
  };

  const formatDevices = () => {
    const data = devices.map((device) => ({
      id: device.id,
      account_id: device.account_id,
      account_name: device.account_name,
      name: device.name,
      manage_name: device.manage_name,
      type: device.type,
      type_name: deviceType(device.type),
      activation: device.activation,
      status: device.status,
      is_monitoring: device.is_monitoring,
      is_connect: device.is_connect,
      lan_ip: device.lan_ip,
    }));
    setFormattedDevices(data);
  };

  return { formatDevices, formattedDevices };
};
