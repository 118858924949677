import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { ReqEditDeviceInfo, ResDeviceListType, ResOneDeviceType } from '../../../types/api/Devices';
import { AccountType } from '../../../types/api/Accounts';
import { ResDeviceModelListType } from '../../../types/api/DeviceModels';
import { useEditDeviceInfo } from '../../../hooks/useEditDeviceInfo';

const device_types = [
  { id: 1, name: 'Edge Controller' },
  { id: 2, name: 'Pad' },
  { id: 3, name: 'AIBOX' },
  { id: 4, name: 'thermo' },
];

// バリデーションルール
const schema = yup.object({
  account_id: yup.string().required('選択してください'),
  device_model_id: yup.string().required('選択してください'),
  type: yup.number().typeError('選択してください'),
  identified_code: yup.string().required('必須項目です'),
  name: yup.string().required('必須項目です'),
  manage_name: yup.string().required('必須項目です'),
  login_id: yup.string(),
  login_password: yup.string(),
  parent_device_id: yup.mixed().notRequired(),
});

type Props = {
  id: string;
  device: ResOneDeviceType;
  accounts: Array<AccountType>;
  deviceModels: Array<ResDeviceModelListType>;
  devices: Array<ResDeviceListType>;
};

export const DeviceEdit: React.VFC<Props> = memo((props) => {
  const { id, device, accounts, deviceModels, devices } = props;
  const { editDeviceInfo, loading, error } = useEditDeviceInfo();
  const hisotry = useHistory();
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqEditDeviceInfo>({
    defaultValues: {
      account_id: device.account_id,
      device_model_id: device.device_model_id,
      type: device.type,
      identified_code: device.identified_code,
      name: device.name,
      manage_name: device.manage_name,
      login_id: device.login_id,
      login_password: device.login_password,
      parent_device_id: device.parent_device_id,
    },
    resolver: yupResolver(schema),
  });
  const watchDeviceType = watch('type');
  const watchAccountId = watch('account_id');

  const devcieEdit: SubmitHandler<ReqEditDeviceInfo> = (data) => {
    editDeviceInfo(id, data);
  };

  useEffect(() => {
    setValue('type', device.type);
    setValue('device_model_id', device.device_model_id);
    setValue('account_id', device.account_id);
    setValue('identified_code', device.identified_code);
    setValue('name', device.name);
    setValue('manage_name', device.manage_name);
    setValue('login_id', device.login_id);
    setValue('login_password', device.login_password);
    setValue('parent_device_id', device.parent_device_id);
  }, [device]);

  return (
    <Box maxWidth="sm" sx={{ py: 5 }}>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Typography align="left" component="div">
          デバイス情報変更
        </Typography>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="form-device-type-label">デバイスタイプ</InputLabel>
            <Controller
              render={({ field }) => (
                <Select id="form-device-type" label="デバイスタイプ*" {...field}>
                  {device_types.length > 0
                    ? device_types.map((device_type) => (
                        <MenuItem key={device_type.id} value={device_type.id}>
                          {device_type.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              )}
              control={control}
              name="type"
              defaultValue={device.type}
            />
            <FormHelperText>{errors.type?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth size="small" required error={'device_model_id' in errors}>
            <InputLabel id="demo-simple-select-label">デバイスモデル</InputLabel>
            <Controller
              render={({ field }) => (
                <Select id="demo-simple-select" label="デバイスモデル*" {...field}>
                  {deviceModels.length > 0
                    ? deviceModels.map(
                        // 選択したデバイスタイプのデバイスモデルのみ表示
                        (deviceModel) =>
                          watchDeviceType === deviceModel.device_type && (
                            <MenuItem key={deviceModel.id} value={deviceModel.id}>
                              {deviceModel.name}
                            </MenuItem>
                          )
                      )
                    : null}
                </Select>
              )}
              control={control}
              name="device_model_id"
              defaultValue={device.device_model_id}
            />
            <FormHelperText>{errors.device_model_id?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth size="small" required error={'account_id' in errors}>
            <InputLabel id="demo-simple-select-label">アカウント</InputLabel>
            <Controller
              render={({ field }) => (
                <Select id="demo-simple-select" label="アカウント*" {...field}>
                  {accounts.length > 0
                    ? accounts.map((account) => (
                        <MenuItem key={account.id} value={account.id}>
                          {account.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              )}
              control={control}
              name="account_id"
              defaultValue={device.account_id}
            />
            <FormHelperText>{errors.account_id?.message}</FormHelperText>
          </FormControl>
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="デバイス識別コード"
                {...field}
                error={'identified_code' in errors}
                helperText={errors.identified_code?.message}
                size="small"
              />
            )}
            control={control}
            name="identified_code"
            defaultValue={device.identified_code}
          />
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="デバイス名(Pad表示用)"
                {...field}
                error={'name' in errors}
                helperText={errors.name?.message}
                size="small"
              />
            )}
            control={control}
            name="name"
            defaultValue={device.name}
          />
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="デバイス名(管理用)"
                {...field}
                error={'manage_name' in errors}
                helperText={errors.manage_name?.message}
                size="small"
              />
            )}
            control={control}
            name="manage_name"
            defaultValue={device.manage_name}
          />
          {
            // EEの場合は必要なし
            watchDeviceType === 1 || (
              <>
                <Controller
                  render={({ field }) => (
                    <TextField
                      required
                      label="ログインID"
                      {...field}
                      error={'login_id' in errors}
                      helperText={errors.login_id?.message}
                      size="small"
                    />
                  )}
                  control={control}
                  name="login_id"
                  defaultValue={device.login_id}
                />
                <Controller
                  render={({ field }) => (
                    <TextField
                      required
                      label="パスワード"
                      {...field}
                      error={'login_password' in errors}
                      helperText={errors.login_password?.message}
                      size="small"
                    />
                  )}
                  control={control}
                  name="login_password"
                  defaultValue={device.login_password}
                />
                <FormControl fullWidth size="small" error={'parent_device_id' in errors}>
                  <InputLabel id="demo-simple-select-label">Edge Controller</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select id="demo-simple-select" label="Edge Controller" {...field}>
                        {devices.length > 0
                          ? devices.map(
                              (device) =>
                                device.type === 1 &&
                                watchAccountId === device.account_id && (
                                  <MenuItem key={device.id} value={device.id}>
                                    {device.manage_name}
                                  </MenuItem>
                                )
                            )
                          : null}
                      </Select>
                    )}
                    control={control}
                    name="parent_device_id"
                    defaultValue={device.parent_device_id}
                  />
                  <FormHelperText>{errors.parent_device_id?.message}</FormHelperText>
                </FormControl>
              </>
            )
          }
        </Stack>
      </Stack>
      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2, px: 3 }} onClick={handleSubmit(devcieEdit)}>
          保存
        </Button>
      </div>
    </Box>
  );
});
