import React, { useEffect } from 'react';
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';

import { Template } from '../components/templates/Template';
import { TabPanel } from '../components/utilities/TabPanel';
import { AccountInfo } from '../components/accounts/AccountInfo';
import { UserList } from '../components/user/UserList';
import { AccountStatus } from '../components/accounts/AccountStatus';
import { useCheckToken } from '../hooks/useCheckToken';
import { useOneAccount } from '../hooks/useOneAccount';

type AccountDetailProps = RouteComponentProps<{
  id: string;
}>;

// React Component
export const AccountDetailPage: React.VFC<AccountDetailProps> = (props) => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const { id } = props.match.params;
  const [value, setValue] = React.useState(0);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const hisotry = useHistory();
  const { getAccount, account } = useOneAccount();

  const toAccountIndex = () => {
    hisotry.push('/accounts');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getAccount(id);
    if (query.get('tab')) {
      setValue(Number(query.get('tab')));
    }
  }, []);

  return (
    <Template title={`アカウント詳細（${account.name}）`} menuId={0}>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mt: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            minWidth: 650,
          }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="device tabs" sx={{ flexGrow: 1 }}>
            <Tab label="アカウント基本情報" />
            {/* <Tab label="契約者情報" /> */}
            <Tab label="ユーザー情報" />
            <Tab label="アカウントの状態" />
          </Tabs>
          <div dir="rtl">
            <Button variant="contained" onClick={toAccountIndex} color="secondary" size="small">
              一覧に戻る
            </Button>
          </div>
        </Box>
        <TabPanel value={value} index={0}>
          <AccountInfo id={id} account={account} getAccount={getAccount} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserList id={id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AccountStatus id={id} status={account.status} getAccount={getAccount} />
        </TabPanel>
      </Box>
    </Template>
  );
};
