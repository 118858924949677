import React, { useState, memo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Box, Tab, Tabs, Button } from '@mui/material';

import { Template } from '../components/templates/Template';
import { DeviceList } from '../components/devices/index/DevicesList';
import { TabPanel } from '../components/utilities/TabPanel';
import { useAllDevices } from '../hooks/useAllDevices';
import { DeviceListType } from '../types/api/Devices';
import { useFormattingDevices } from '../hooks/useFormattingDevices';
import { useCheckToken } from '../hooks/useCheckToken';

export const DeviceIndex: React.VFC = memo(() => {
  const [value, setValue] = useState(0);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const hisotry = useHistory();
  const [faceDevices, setFaceDevices] = useState<Array<DeviceListType>>([]);
  const [exDevices, setExDevices] = useState<Array<DeviceListType>>([]);
  const { getDevices, devices, loading: deviceLoading, error: deviceError } = useAllDevices();
  const { formatDevices, formattedDevices } = useFormattingDevices(devices);
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (query.get('tab')) {
      setValue(Number(query.get('tab')));
    }
  }, []);

  const toDeviceEdit = () => {
    hisotry.push('/devices/edit');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // デバイス振り分け処理
  const splitDevices = (devices: Array<DeviceListType>) => {
    let ex: Array<DeviceListType> = [];
    let face: Array<DeviceListType> = [];
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].type == 1) {
        ex = [...ex, devices[i]];
      } else {
        face = [...face, devices[i]];
      }
    }
    setExDevices(ex);
    setFaceDevices(face);
  };

  // ここでAPIをたたく
  React.useEffect(() => {
    getDevices();
  }, [getDevices]);

  // デバイスにアカウント名と施設名を追加
  React.useEffect(() => {
    formatDevices();
  }, [devices]);

  // デバイスをEEとその他に振り分け
  React.useEffect(() => {
    splitDevices(formattedDevices);
  }, [formattedDevices]);

  return (
    <Template title={'デバイス一覧'} menuId={1}>
      <Box sx={{ width: '100%' }}>
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2 }} onClick={toDeviceEdit}>
            デバイス登録
          </Button>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="device tabs">
            <Tab label="顔認証デバイス一覧" />
            <Tab label="Edge Controller 一覧" sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DeviceList devices={faceDevices} getDevices={getDevices} showType={true} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DeviceList devices={exDevices} getDevices={getDevices} showType={false} />
        </TabPanel>
      </Box>
    </Template>
  );
});
