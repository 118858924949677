import React, { useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';

import { Template } from '../components/templates/Template';
import { DeviceEdit } from '../components/devices/detail/DeviceEdit';
import { DeviceSetting } from '../components/devices/detail/DeviceLanSetting';
import { TabPanel } from '../components/utilities/TabPanel';
import { DeviceStatus } from '../components/devices/detail/DeviceStatus';
import { DeviceMonitoring } from '../components/devices/detail/DeviceMonitoring';
import { useOneDevices } from '../hooks/useOneDevice';
import { useAllAccounts } from '../hooks/useAllAccounts';
import { useAllDeviceModels } from '../hooks/useAllDeviceModels';
import { useAllDevices } from '../hooks/useAllDevices';
import { useCheckToken } from '../hooks/useCheckToken';

type Props = RouteComponentProps<{
  id: string;
}>;

export const DeviceDetail: React.VFC<Props> = (props) => {
  const id = props.match.params.id;

  const [value, setValue] = React.useState(0);
  const hisotry = useHistory();
  const { getDevice, device, loading, error } = useOneDevices(id);
  const { getAccounts, accounts, loading: accountLoading, error: accountError } = useAllAccounts();
  const { getDeviceModels, deviceModels, loading: deviceModelLoading, error: deviceModelError } = useAllDeviceModels();
  const { getDevices, devices, loading: deviceLoading, error: deviceError } = useAllDevices();
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);

  const toDeviceIndex = () => {
    hisotry.push('/devices');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // API
  useEffect(() => {
    getAccounts();
  }, [getAccounts]);
  useEffect(() => {
    getDeviceModels();
  }, [getDeviceModels]);
  useEffect(() => {
    getDevices();
  }, [getDevices]);
  useEffect(() => {
    getDevice();
  }, [getDevice]);

  return (
    <Template title={device ? `デバイス設定（${device?.manage_name}）` : 'デバイス設定'} menuId={1}>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mt: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            minWidth: 650,
          }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="device tabs" sx={{ flexGrow: 1 }}>
            <Tab label="デバイス情報" />
            <Tab label="ネットワーク設定" />
            <Tab label="監視 有効 / 無効" />
            <Tab label="デバイス 有効 / 無効" />
          </Tabs>
          <div dir="rtl">
            <Button variant="contained" onClick={toDeviceIndex} color="secondary" size="small">
              一覧に戻る
            </Button>
          </div>
        </Box>
        <TabPanel value={value} index={0}>
          <DeviceEdit id={id} device={device} accounts={accounts} deviceModels={deviceModels} devices={devices} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DeviceSetting id={id} device={device} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DeviceMonitoring id={id} device={device} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DeviceStatus id={id} device={device} />
        </TabPanel>
      </Box>
    </Template>
  );
};
