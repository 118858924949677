import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Login } from '../pages/Login';

// Account
import { AccountListPage } from '../pages/AccountListPage';
import { AccountRegistrationPage } from '../pages/AccountRegistrationPage';
import { AccountDetailPage } from '../pages/AccountDetailPage';
// User
import { UserRegistrationPage } from '../pages/UserRegistrationPage';
import { UserEditPage } from '../pages/UserEditPage';

// Device
import { DeviceIndex } from '../pages/DeviceIndex';
import { DeviceDetail } from '../pages/DeviceDetail';
import { DeviceRegister } from '../pages/DeviceRegister';

// Group
import { GroupIndex } from '../pages/GroupIndex';
import { GroupEdit } from '../pages/GroupEdit';
import { GroupRegister } from '../pages/GroupRegister';

// Task Queue
import { TaskQueueListPage } from '../pages/TaskQueueListPage';

export const Router: React.VFC = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/accounts" />
      </Route>
      <Route path="/login" component={Login} exact />
      <Route path="/accounts" component={AccountListPage} exact />
      <Route path="/accounts/new" component={AccountRegistrationPage} exact />
      <Route path="/accounts/details/:id" component={AccountDetailPage} exact />
      <Route path="/accounts/:account_id/users/new" component={UserRegistrationPage} exact />
      <Route path="/accounts/:account_id/users/edit/:id" component={UserEditPage} exact />
      <Route path="/devices" component={DeviceIndex} exact />
      <Route path="/devices/edit" component={DeviceRegister} exact />
      {/* <Route path="/devices/edit/:id" component={DeviceEdit} exact /> */}
      <Route path="/devices/details/:id" component={DeviceDetail} exact />
      <Route path="/groups" component={GroupIndex} exact />
      <Route path="/groups/edit" component={GroupRegister} exact />
      <Route path="/groups/edit/:id" component={GroupEdit} exact />
      <Route path="/taskqueues" component={TaskQueueListPage} exact />
    </Switch>
  );
};
