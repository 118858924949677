import { useCallback, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

export const useDeleteUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const deleteUser = useCallback(async (id: string) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.delete(`${ApiUrl}/admin/api/v1/users/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      });
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { deleteUser, loading, error };
};
