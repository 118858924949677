import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useLogout = () => {
  const history = useHistory();

  const logout = useCallback(() => {
    sessionStorage.removeItem('token');
    history.push('/login');
  }, []);

  return { logout };
};
