import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResGetGroups } from '../types/api/Groups';
import { ApiUrl } from '../conf/config';

export const useAccountGroups = () => {
  const [groups, setGroups] = useState<Array<ResGetGroups>>([]);
  const [notExists, setNotExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountGroups = useCallback((account_id) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/groups?account_id=${account_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.linkage_groups === null) {
          setNotExists(true);
          setGroups([]);
        } else {
          setGroups(res.data.linkage_groups);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getAccountGroups, groups, notExists, loading, error };
};
