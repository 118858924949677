import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResOneDeviceType } from '../types/api/Devices';
import { ApiUrl } from '../conf/config';

export const useOneDevices = (id: string) => {
  const [device, setDevice] = useState<ResOneDeviceType>({
    id: '',
    account_id: '',
    device_model_id: '',
    type: 0,
    identified_code: '',
    activation: 0,
    status: 0,
    name: '',
    manage_name: '',
    lan_setting_enable: 0,
    lan_dhcp: 0,
    lan_ip: '',
    lan_mask: '',
    lan_gateway: '',
    lan_dns1: '',
    lan_dns2: '',
    login_id: '',
    login_password: '',
    is_connect: 0,
    is_monitoring: 0,
    parent_device_id: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getDevice = useCallback(() => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/devices/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        setDevice(res.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getDevice, device, loading, error };
};
