import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Template } from '../components/templates/Template';
import { TablePaginationActions } from '../components/utilities/TablePagination';

import { useCheckToken } from '../hooks/useCheckToken';
import { AccountType } from '../types/api/Accounts';
import { useAllTaskQueues } from '../hooks/useAllTaskQueues';

const searchAccount = (targetAccounts: AccountType[], word: string): AccountType[] => {
  if (word === '') {
    return targetAccounts;
  }

  return targetAccounts.filter((account) => account.name.toLowerCase().includes(word.toLowerCase()));
};

// React Component
export const TaskQueueListPage: React.VFC = () => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const hisotry = useHistory();
  const [searchWord, setSearchWord] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [data, setData] = useState('');
  const { getTaskQueues, taskQueues } = useAllTaskQueues();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const showAllData = (taskData: string) => {
    const taskDataObj = JSON.parse(taskData);
    setData(JSON.stringify(taskDataObj, null, 4));
    setOpen(true);
  };

  // Table Pagination ------------------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - taskQueues.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ---------------------------------------------------

  useEffect(() => {
    getTaskQueues();
  }, []);

  return (
    <Template title={'キュー一覧'} menuId={3}>
      {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          setSearchWord(searchInput);
        }}
        style={{ marginBottom: '2rem', display: 'flex', alignItems: 'center' }}
      >
        <TextField
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          placeholder="アカウント名で検索"
          size="small"
          sx={{ width: '50ch' }}
        />
        <Button variant="contained" onClick={() => setSearchWord(searchInput)} sx={{ ml: 1 }}>
          検索
        </Button>
        {searchWord !== '' && <Box sx={{ ml: 3 }}>{`“${searchWord}”の検索結果 ${searchedAccounts.length}件`} </Box>}
      </form> */}

      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb', mt: 3 }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts table"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>アカウント名</TableCell>
              <TableCell>EC</TableCell>
              <TableCell>デバイス名</TableCell>
              <TableCell>タスクコード</TableCell>
              <TableCell>タスクサブコード</TableCell>
              <TableCell>タスクデータ</TableCell>
              <TableCell>ステータス</TableCell>
              <TableCell>メッセージ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? taskQueues.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : taskQueues
            ).map((taskQueue) => (
              <TableRow key={taskQueue.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {taskQueue.id}
                </TableCell>
                <TableCell>{taskQueue.account_name}</TableCell>

                <TableCell>{taskQueue.parent_device_name}</TableCell>
                <TableCell>{taskQueue.device_name}</TableCell>
                <TableCell>
                  {taskQueue.task_code === 1
                    ? '1（デバイス情報変更）'
                    : taskQueue.task_code === 2
                    ? '2 (ファームウェアアップデート)'
                    : taskQueue.task_code === 3
                    ? '3 (顔同期)'
                    : ''}
                </TableCell>
                <TableCell>
                  {taskQueue.task_code !== 1
                    ? '-'
                    : taskQueue.task_sub_code === 1
                    ? '1（デバイス登録）'
                    : taskQueue.task_sub_code === 2
                    ? '2（デバイス情報更新）'
                    : taskQueue.task_sub_code === 3
                    ? '3（デバイスLAN設定更新）'
                    : taskQueue.task_sub_code === 4
                    ? '4（デバイス削除）'
                    : ''}
                </TableCell>
                <TableCell>
                  {taskQueue.task_data.length > 50 ? (
                    <>
                      {taskQueue.task_data.slice(0, 50) + ' ...'}
                      <IconButton
                        color="primary"
                        aria-label="settings"
                        component="span"
                        onClick={() => showAllData(taskQueue.task_data)}
                        sx={{ height: '1.75rem' }}
                      >
                        <VisibilityIcon sx={{ color: '#757575' }} />
                      </IconButton>
                    </>
                  ) : (
                    taskQueue.task_data
                  )}
                </TableCell>
                <TableCell>
                  {taskQueue.status == 0
                    ? '実行前'
                    : taskQueue.status == 1
                    ? '実行中'
                    : taskQueue.status == 2
                    ? '正常終了'
                    : taskQueue.status == 3
                    ? '異常終了'
                    : ''}
                </TableCell>
                <TableCell>{taskQueue.response_message}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={9}
                count={taskQueues.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflowX: 'auto',
          }}
        >
          <Typography id="modal-modal-description">
            <pre>{data}</pre>
          </Typography>
        </Box>
      </Modal>
    </Template>
  );
};
