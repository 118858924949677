import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAccountUsers } from '../../hooks/useAccountUsers';
import { TablePaginationActions } from '../utilities/TablePagination';
import { useDeleteUser } from '../../hooks/useDeleteUser';

type Props = {
  id: string;
};

export const UserList: React.VFC<Props> = (props) => {
  const { id } = props;
  const history = useHistory();
  const { getAccountUsers, users } = useAccountUsers();
  const { deleteUser } = useDeleteUser();

  const toUserEditNew = () => {
    history.push(`/accounts/${id}/users/new`);
  };
  const toUserEdit = (userId: string) => {
    history.push(`/accounts/${id}/users/edit/${userId}`);
    console.log('edit');
  };
  const userDelete = async (userId: string) => {
    const result = window.confirm('ユーザーを削除してもよろしいですか？');
    if (!result) {
      return;
    }
    await deleteUser(userId);
    await getAccountUsers(id);
  };

  // Table Pagination ------------------------------
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ------------------------------------------------

  useEffect(() => {
    getAccountUsers(id);
  }, []);

  return (
    <>
      <Typography component="h2" variant="h6" color="inherit" noWrap sx={{ my: 3 }}>
        ユーザー情報
      </Typography>

      <div dir="rtl">
        <Button variant="contained" sx={{ my: 2 }} onClick={toUserEditNew}>
          ユーザー登録
        </Button>
      </div>

      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb' }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts info table"
        >
          <TableHead>
            <TableRow>
              <TableCell>ユーザー名</TableCell>
              <TableCell>メールアドレス</TableCell>
              {/* <TableCell>タイプ</TableCell> */}
              <TableCell>権限</TableCell>
              <TableCell>部署</TableCell>
              <TableCell>電話番号</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : users).map(
              (user) => (
                <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  {/* <TableCell>ダッシュボードユーザー</TableCell> */}
                  <TableCell>{user.role_id === 1 ? '管理者' : '一般ユーザー'}</TableCell>
                  <TableCell>{user.department}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      aria-label="settings"
                      component="span"
                      sx={{ mx: 1 }}
                      onClick={() => {
                        toUserEdit(user.id);
                      }}
                    >
                      <EditIcon sx={{ color: '#757575' }} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="delete"
                      component="span"
                      sx={{ mx: 1 }}
                      onClick={() => {
                        userDelete(user.id);
                      }}
                    >
                      <DeleteIcon sx={{ color: '#757575' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
