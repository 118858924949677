import React, { useState, useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material UI components
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// custom
import { Template } from '../components/templates/Template';
import { useAccountDevices } from '../hooks/useAccountDevices';
import { ReqAddGroup, ReqEditGroup, ReqEditGroupFrom } from '../types/api/Groups';
import { ResAccountDevicesType } from '../types/api/Devices';
import { useEditGroup } from '../hooks/useEditGroup';
import { useOneGroup } from '../hooks/useOneGroup';
import { useCheckToken } from '../hooks/useCheckToken';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
});

type Props = RouteComponentProps<{
  id: string;
}>;

// React Component
export const GroupEdit: React.VFC<Props> = (props) => {
  const { id } = props.match.params;

  const history = useHistory();
  const [deviceList, setDeviceList] = useState<Array<ResAccountDevicesType>>([]);
  const [deviceNotSelected, setDeviceNotSelected] = useState<Array<ResAccountDevicesType>>([]);

  // custom hooks
  const { getGroup, group, loading: groupLoading, error: groupError } = useOneGroup(id);
  const { getAccountDevices, devices, notExists, loading: deviceLoading, error: deviceError } = useAccountDevices();
  const { editGroup, loading, error } = useEditGroup();
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);

  // react hook form
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqEditGroupFrom>({
    defaultValues: {
      account_id: group.account_id,
      name: group.name,
      memo: group.memo,
      devices: group.devices,
    },
    resolver: yupResolver(schema),
  });

  // デバイス一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  const filterDevice = (allDevices: Array<ResAccountDevicesType>, listDevices: Array<ResAccountDevicesType>) => {
    let notExistDevice: Array<ResAccountDevicesType> = [];

    notExistDevice = allDevices.filter((device) => {
      for (let v of listDevices) {
        if (v.id === device.id) {
          return false;
        }
      }
      return true;
    });

    setDeviceNotSelected(notExistDevice);
  };

  // デバイス登録
  const sendGroup: SubmitHandler<ReqEditGroupFrom> = (data) => {
    let reqEditGroupDevices: string[] = [];
    for (let v of deviceList) {
      reqEditGroupDevices = [...reqEditGroupDevices, v.id];
    }

    let reqEditGroup: ReqEditGroup = {
      name: data.name,
      memo: data.memo,
      device_ids: reqEditGroupDevices,
    };

    editGroup(id, reqEditGroup);
    if (!error && !loading) {
      alert('グループを編集しました。');
      toGroupIndex();
    }
  };

  React.useEffect(() => {
    setValue('account_id', group.account_id);
    setValue('name', group.name);
    setValue('memo', group.memo);
    setDeviceList(group.devices);
    getAccountDevices(group.account_id);
  }, [group]);

  React.useEffect(() => {
    filterDevice(devices, deviceList);
  }, [devices]);

  // API
  React.useEffect(() => {
    getGroup();
  }, []);

  console.log('device list', deviceList);
  console.log('device not list', deviceNotSelected);

  return (
    <Template title="グループ編集" menuId={2}>
      <Box maxWidth="sm" sx={{ py: 5 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="form-device-type-label">アカウント</InputLabel>
            <Controller
              render={({ field }) => (
                <Select id="form-device-type" label="アカウント*" {...field} disabled>
                  <MenuItem key={group.account_id} value={group.account_id}>
                    {group.account_name}
                  </MenuItem>
                </Select>
              )}
              control={control}
              name="account_id"
              defaultValue={group.account_id}
            />
          </FormControl>
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="グループ名"
                {...field}
                error={'name' in errors}
                helperText={errors.name?.message}
                size="small"
              />
            )}
            control={control}
            name="name"
            defaultValue={group.name}
          />
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="メモ"
                {...field}
                error={'memo' in errors}
                helperText={errors.memo?.message}
                size="small"
              />
            )}
            control={control}
            name="memo"
            defaultValue={group.memo}
          />
          <Controller
            render={({ field }) => (
              <Autocomplete
                multiple
                {...field}
                id="checkboxes-tags-demo"
                value={deviceList}
                onChange={(event, newValue) => {
                  setDeviceList([...newValue]);
                  filterDevice(devices, [...newValue]);
                }}
                // TODO: deviceが0のときのエラー修正
                options={[...deviceList, ...deviceNotSelected].sort(function (a, b) {
                  return a.id < b.id ? -1 : 1; //昇順ソート
                })}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField {...params} label="デバイス" placeholder="デバイスを選択" size="small" />
                )}
              />
            )}
            control={control}
            name="devices"
            defaultValue={deviceList}
          />
        </Stack>
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(sendGroup)}>
            編集
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toGroupIndex}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
