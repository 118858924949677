import React, { useState, memo, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ReqEditDeviceStatus, ResOneDeviceType } from '../../../types/api/Devices';
import { useEditDeviceStatus } from '../../../hooks/useEditDeviceStatus';

type Props = {
  id: string;
  device: ResOneDeviceType;
};

export const DeviceStatus: React.VFC<Props> = memo((props) => {
  const { id, device } = props;
  const [status, setStatus] = useState<number>();
  const { editDeviceStatus, loading, error } = useEditDeviceStatus();

  const onClickEditDeviceStatus = (status: number) => {
    const deviceStatus: ReqEditDeviceStatus = {
      status: status,
    };
    editDeviceStatus(id, deviceStatus);
    if (!error && !loading) {
      alert('デバイス情報を編集しました。');
      setStatus(status);
    }
  };

  useEffect(() => {
    setStatus(device.status);
  }, [device]);

  return (
    <Box maxWidth="sm" sx={{ py: 5, display: 'flex' }}>
      {status === 1 ? (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>デバイスの状態： 有効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            color="error"
            onClick={() => {
              onClickEditDeviceStatus(0);
            }}
          >
            無効化する
          </Button>
        </>
      ) : (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>デバイスの状態： 無効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            onClick={() => {
              onClickEditDeviceStatus(1);
            }}
          >
            有効化する
          </Button>
        </>
      )}
    </Box>
  );
});
