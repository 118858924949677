import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { AccountType, ReqAddEditAccount } from '../../types/api/Accounts';
import { useEditAccount } from '../../hooks/useEditAccount';

const face_manage_types = [
  { id: 1, name: 'KaoBank' },
  { id: 2, name: 'MyAnchor' },
];

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
  face_manage_type: yup.number().typeError('選択してください'),
});

type Props = {
  id: string;
  account: AccountType;
  getAccount: (id: string) => Promise<void>;
};

// React Component
export const AccountInfo: React.VFC<Props> = (props) => {
  const { id, account, getAccount } = props;
  const history = useHistory();
  const [isEditting, setIsEditting] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ReqAddEditAccount>({
    resolver: yupResolver(schema),
  });
  const { editAccount } = useEditAccount();

  const accountEdit: SubmitHandler<ReqAddEditAccount> = async (data) => {
    console.log(data);
    await editAccount(id, data);
    await getAccount(id);
  };

  useEffect(() => {
    setValue('name', account.name);
    setValue('face_manage_type', account.face_manage_type);
  }, [account]);

  return (
    <>
      <Box maxWidth="sm" sx={{ py: 5 }}>
        <Typography component="h2" variant="h6" color="inherit" noWrap>
          アカウント情報
        </Typography>
        <div dir="rtl">
          {isEditting ? (
            <Button
              variant="contained"
              color="secondary"
              sx={{ mb: 2, px: 4 }}
              onClick={() => {
                setIsEditting(false);
                setValue('name', account.name);
                setValue('face_manage_type', account.face_manage_type);
              }}
            >
              キャンセル
            </Button>
          ) : (
            <Button variant="contained" sx={{ mb: 2, px: 4 }} onClick={() => setIsEditting(true)}>
              編集する
            </Button>
          )}
        </div>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="アカウント名"
                {...field}
                error={'name' in errors}
                helperText={errors.name?.message}
                size="small"
                disabled={!isEditting}
              />
            )}
            control={control}
            name="name"
            defaultValue={account.name}
          />
          <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="demo-simple-select-label">顔管理システム</InputLabel>
            <Controller
              render={({ field }) => (
                <Select id="form-device-type" label="顔管理システム*" {...field} disabled={!isEditting}>
                  {face_manage_types.length > 0
                    ? face_manage_types.map((face_manage_type) => (
                        <MenuItem key={face_manage_type.id} value={face_manage_type.id}>
                          {face_manage_type.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              )}
              control={control}
              name="face_manage_type"
              defaultValue={account.face_manage_type}
            />
            <FormHelperText>{errors.face_manage_type?.message}</FormHelperText>
          </FormControl>
        </Stack>

        {isEditting && (
          <div dir="rtl">
            <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(accountEdit)}>
              保存
            </Button>
          </div>
        )}
      </Box>
    </>
  );
};
