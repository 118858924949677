import { useState, useCallback } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { UserType } from '../types/api/Users';

export const useOneUser = () => {
  const [user, setUser] = useState<UserType>({
    id: '',
    account_id: '',
    type: 0,
    role_id: 0,
    name: '',
    email: '',
    password: '',
    department: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getUser = useCallback(async (id: string) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.get(`${ApiUrl}/admin/api/v1/users/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200 && res.data !== null) {
        setUser(res.data);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { getUser, user, loading, error };
};
