import { useState, useCallback } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { TaskQueuesType } from '../types/api/TaskQueues';

export const useAllTaskQueues = () => {
  const [taskQueues, setTaskQueues] = useState<Array<TaskQueuesType>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getTaskQueues = useCallback(() => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/taskqueue/list`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.task_queues === null) {
          setTaskQueues([]);
        } else {
          setTaskQueues(res.data.task_queues);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getTaskQueues, taskQueues, loading, error };
};
