import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResAccountDevicesType } from '../types/api/Devices';
import { ApiUrl } from '../conf/config';

export const useAccountDevices = () => {
  const [devices, setDevices] = useState<Array<ResAccountDevicesType>>([]);
  const [notExists, setNotExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccountDevices = useCallback((account_id) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/devices/account?account_id=${account_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.devices === null) {
          setNotExists(true);
          setDevices([]);
        } else {
          setDevices(res.data.devices);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getAccountDevices, devices, notExists, loading, error };
};
