import React from 'react';
import axios from 'axios';
import { ReqEditDeviceStatus } from '../types/api/Devices';
import { ApiUrl } from '../conf/config';

export const useEditDeviceStatus = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editDeviceStatus = (id: string, device: ReqEditDeviceStatus) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .put(`${ApiUrl}/admin/api/v1/devices/${id}/status`, device, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { editDeviceStatus, loading, error };
};
