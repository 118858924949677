import React, { useState, memo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, red, grey } from '@mui/material/colors';

import { TablePaginationActions } from '../../utilities/TablePagination';
import { DeviceListType } from '../../../types/api/Devices';
import { useDeleteDevice } from '../../../hooks/useDeleteDevice';

type Props = {
  devices: DeviceListType[];
  getDevices: VoidFunction;
  showType: boolean;
};

const searchDevice = (targetDevices: DeviceListType[], word: string): DeviceListType[] => {
  console.log('search');
  if (word === '') {
    return targetDevices;
  }

  return targetDevices.filter(
    (device) =>
      device.account_name.toLowerCase().includes(word.toLowerCase()) ||
      device.manage_name.toLowerCase().includes(word.toLowerCase())
  );
};

export const DeviceList: React.VFC<Props> = memo((props) => {
  const { devices, getDevices, showType } = props;
  const hisotry = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchWord, setSearchWord] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const { deleteDevice, loading, error } = useDeleteDevice();

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const toDetailPage = (id: string) => {
    hisotry.push(`/devices/details/${id}`);
  };

  const onClickDeleteDevice = (id: string) => {
    const result = window.confirm('デバイスを削除してもよろしいですか？');
    if (result) {
      deleteDevice(id);
      if (!error && !loading) {
        alert('デバイスを削除しました。');
        getDevices();
      }
    }
  };

  const searchedDevices = searchDevice(devices, searchWord);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchWord(searchInput);
          }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <TextField
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            placeholder="アカウント名、デバイス名で検索"
            size="small"
            sx={{ width: '50ch' }}
          />
          <Button type="button" variant="contained" onClick={() => setSearchWord(searchInput)} sx={{ ml: 1 }}>
            検索
          </Button>
        </form>
        {searchWord !== '' && <Box sx={{ ml: 3 }}>{`“${searchWord}”の検索結果 ${searchedDevices.length}件`} </Box>}
      </Box>

      <TableContainer component={Paper} sx={{ boxShadow: '1px 1px 10px #bbb', my: 5 }}>
        <Table
          sx={{
            minWidth: 650,
            boxShadow: '1px 1px 6px #ddd',
          }}
          aria-label="accounts table"
        >
          <TableHead>
            <TableRow>
              <TableCell>アカウント</TableCell>
              <TableCell>デバイス名</TableCell>
              {showType && <TableCell>デバイスタイプ</TableCell>}
              <TableCell>IPアドレス</TableCell>
              <TableCell align="center" sx={{ pr: 0 }}>
                アクティベーション
              </TableCell>
              <TableCell align="center" sx={{ px: 0 }}>
                監視ステータス
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? searchedDevices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : searchedDevices
            ).map((device) => (
              <TableRow key={device.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{device.account_name}</TableCell>
                <TableCell component="th" scope="row">
                  {device.manage_name}
                </TableCell>
                {showType && <TableCell>{device.type_name}</TableCell>}
                <TableCell>{device.lan_ip}</TableCell>
                <TableCell align="center" sx={{ px: 0 }}>
                  {device.activation === 1 ? (
                    <CheckCircleOutlineIcon sx={{ color: green[600] }} />
                  ) : (
                    <CancelOutlinedIcon sx={{ color: red[600] }} />
                  )}
                </TableCell>
                <TableCell align="center" sx={{ px: 0 }}>
                  {device.status === 0 || device.activation === 0 || device.is_monitoring === 0 ? (
                    <DoDisturbIcon sx={{ color: grey[500] }} />
                  ) : device.is_connect === 1 ? (
                    <CheckCircleOutlineIcon sx={{ color: green[600] }} />
                  ) : (
                    <CancelOutlinedIcon sx={{ color: red[600] }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    aria-label="settings"
                    component="span"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      toDetailPage(device.id);
                    }}
                  >
                    <SettingsIcon sx={{ color: '#757575' }} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="delete"
                    component="span"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      onClickDeleteDevice(device.id);
                    }}
                  >
                    <DeleteIcon sx={{ color: '#757575' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 73 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={searchedDevices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
});
