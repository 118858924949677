import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { Template } from '../components/templates/Template';
import { ReqAddEditAccount } from '../types/api/Accounts';
import { useAddAccount } from '../hooks/useAddAccount';
import { useCheckToken } from '../hooks/useCheckToken';

const face_manage_types = [
  { id: 1, name: 'KaoBank' },
  { id: 2, name: 'MyAnchor' },
];

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
  face_manage_type: yup.number().typeError('選択してください'),
});

// React Component
export const AccountRegistrationPage: React.VFC = () => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const history = useHistory();
  const { addAccount } = useAddAccount();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReqAddEditAccount>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      face_manage_type: 0,
    },
  });

  const toAccountIndex = () => {
    history.push('/accounts');
  };

  const registerAccount: SubmitHandler<ReqAddEditAccount> = (data) => {
    console.log(data);
    addAccount(data);
  };

  return (
    <Template title={'アカウント登録'} menuId={0}>
      <Box maxWidth="sm" sx={{ py: 5, px: 3 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'default' }}>アカウント情報を入力</Box>
          </Typography>
          <TextField
            required
            label="アカウント名"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            size="small"
          />
          <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="demo-simple-select-label">顔管理システム</InputLabel>
            <Select id="demo-simple-select" {...register('face_manage_type')} label="デバイスタイプ*">
              {face_manage_types.length > 0
                ? face_manage_types.map((face_manage_type) => (
                    <MenuItem key={face_manage_type.id} value={face_manage_type.id}>
                      {face_manage_type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errors.face_manage_type?.message}</FormHelperText>
          </FormControl>
        </Stack>

        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(registerAccount)}>
            アカウント登録
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toAccountIndex}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
