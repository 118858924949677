import { useCallback, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ReqAddEditAccount } from '../types/api/Accounts';

export const useEditAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const editAccount = useCallback(async (id: string, account: ReqAddEditAccount) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.put(`${ApiUrl}/admin/api/v1/accounts/${id}`, account, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200) {
        alert('アカウント情報を保存しました。');
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { editAccount, loading, error };
};
