import React, { useState, memo, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useEditDeviceMonitoring } from '../../../hooks/useEditDeviceMonitoring';
import { ReqEditDeviceMonitoring, ResOneDeviceType } from '../../../types/api/Devices';

type Props = {
  id: string;
  device: ResOneDeviceType;
};

export const DeviceMonitoring: React.VFC<Props> = memo((props) => {
  const { id, device } = props;
  const [monitoring, setMonitoring] = useState<number>();
  const { editDeviceMonitoring, loading, error } = useEditDeviceMonitoring();

  const onClickEditDeviceMonitoring = (is_monitoring: number) => {
    const deviceMonitoring: ReqEditDeviceMonitoring = {
      is_monitoring: is_monitoring,
    };
    editDeviceMonitoring(id, deviceMonitoring);
    if (!error && !loading) {
      alert('デバイス情報を編集しました。');
      setMonitoring(is_monitoring);
    }
  };

  useEffect(() => {
    setMonitoring(device.is_monitoring);
  }, [device]);

  return (
    <Box maxWidth="sm" sx={{ py: 5, display: 'flex' }}>
      {monitoring === 1 ? (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>監視設定： 有効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            color="error"
            onClick={() => {
              onClickEditDeviceMonitoring(0);
            }}
          >
            無効化する
          </Button>
        </>
      ) : (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>監視設定： 無効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            onClick={() => {
              onClickEditDeviceMonitoring(1);
            }}
          >
            有効化する
          </Button>
        </>
      )}
    </Box>
  );
});
