import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './router/Router';
import { ThemeProvider } from '@mui/material/styles';
import { Theme } from './conf/theme';

export const App: React.VFC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
};
