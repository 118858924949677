import { useCallback, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ReqEditAccountStatus } from '../types/api/Accounts';

export const useEditAccountStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const editAccountStatus = useCallback(async (id: string, reqStatus: number) => {
    setError(false);
    setLoading(true);

    const req: ReqEditAccountStatus = {
      status: reqStatus,
    };

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.put(`${ApiUrl}/admin/api/v1/accounts/${id}/status`, req, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200) {
        alert('アカウントの状態を変更しました。');
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { editAccountStatus, loading, error };
};
