import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { Template } from '../components/templates/Template';
import { useCheckToken } from '../hooks/useCheckToken';
import { useAddUser } from '../hooks/useAddUser';
import { AddEditUserForm, ReqAddEditUser } from '../types/api/Users';

const types = [{ id: 1, name: 'ダッシュボードユーザー' }];

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
  face_manage_type: yup.number().typeError('選択してください'),
});

type Props = RouteComponentProps<{
  account_id: string;
}>;

// React Component
export const UserRegistrationPage: React.VFC<Props> = (props) => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const { account_id } = props.match.params;
  const history = useHistory();
  const { addUser } = useAddUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddEditUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 1,
      name: '',
      email: '',
      password: '',
      department: '',
      phone: '',
    },
  });

  const toAccountDetail = () => {
    history.push(`/accounts/details/${account_id}?tab=1`);
  };

  const registerAccount: SubmitHandler<AddEditUserForm> = (data) => {
    console.log(data);
    const req: ReqAddEditUser = {
      account_id: account_id,
      type: data.type,
      name: data.name,
      email: data.email,
      password: data.password,
      department: data.department,
      phone: data.phone,
    };
    console.log(req);
    addUser(req);
  };

  return (
    <Template title={'ユーザー登録'} menuId={0}>
      <Box maxWidth="sm" sx={{ py: 5, px: 3 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <TextField
            required
            label="ユーザー名"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            size="small"
          />
          {/* <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="demo-simple-select-label">ユーザータイプ</InputLabel>
            <Select id="demo-simple-select" {...register('type')} label="デバイスタイプ*">
              {types.length > 0
                ? types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errors.type?.message}</FormHelperText>
          </FormControl> */}
          <TextField
            required
            label="メールアドレス"
            {...register('email')}
            error={'email' in errors}
            helperText={errors.email?.message}
            size="small"
          />
          <TextField
            required
            label="パスワード"
            {...register('password')}
            error={'password' in errors}
            helperText={errors.password?.message}
            size="small"
          />
          <TextField
            label="部署"
            {...register('department')}
            error={'department' in errors}
            helperText={errors.department?.message}
            size="small"
          />
          <TextField
            label="電話番号"
            {...register('phone')}
            error={'phone' in errors}
            helperText={errors.phone?.message}
            size="small"
          />
        </Stack>

        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(registerAccount)}>
            ユーザー登録
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toAccountDetail}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
