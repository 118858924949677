import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ReqEditDeviceInfo } from '../types/api/Devices';
import { ApiUrl } from '../conf/config';

export const useEditDeviceInfo = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const hisotry = useHistory();

  const editDeviceInfo = useCallback(async (id: string, device: ReqEditDeviceInfo) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.put(`${ApiUrl}/admin/api/v1/devices/${id}`, device, {
        headers: { Authorization: 'Bearer ' + token },
      });
      alert('デバイス情報を編集しました。');
      hisotry.push('/devices');
    } catch (e) {
      console.error(e);
      setError(true);
      alert('デバイス情報の編集に失敗しました。');
    } finally {
      setLoading(false);
    }
  }, []);

  return { editDeviceInfo, loading, error };
};
