import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../conf/config';

import { ReqCheckToken } from '../types/api/auth';

export const useCheckToken = () => {
  const history = useHistory();

  const checkToken = useCallback(() => {
    const body: ReqCheckToken = {
      token: sessionStorage.getItem('token'),
    };

    axios
      .post(`${ApiUrl}/admin/api/v1/token`, body)
      .then((res) => {})
      .catch((error) => {
        console.error(error);
        history.push('/login');
      });
  }, []);

  return { checkToken };
};
