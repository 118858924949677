import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ReqAddEditAccount } from '../types/api/Accounts';

export const useAddAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const addAccount = useCallback(async (account: ReqAddEditAccount) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.post(`${ApiUrl}/admin/api/v1/accounts`, account, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200) {
        alert('アカウントを登録しました。');
        history.push('/accounts');
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { addAccount, loading, error };
};
