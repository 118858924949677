import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useEditAccountStatus } from '../../hooks/useEditAccountStatus';

type Props = {
  id: string;
  status: number;
  getAccount: (id: string) => Promise<void>;
};

export const AccountStatus: React.VFC<Props> = (props) => {
  const { id, status, getAccount } = props;
  const { editAccountStatus } = useEditAccountStatus();
  console.log(status);

  const onClickEditAccountStatus = async (reqStatus: number) => {
    await editAccountStatus(id, reqStatus);
    await getAccount(id);
  };

  return (
    <Box maxWidth="sm" sx={{ py: 5, display: 'flex' }}>
      {status === 1 ? (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>アカウントの状態： 有効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            color="error"
            onClick={() => {
              onClickEditAccountStatus(0);
            }}
          >
            無効化する
          </Button>
        </>
      ) : status === 0 ? (
        <>
          <Typography align="left" component="div">
            <Box sx={{ fontSize: 'h6.fontSize' }}>アカウントの状態： 無効</Box>
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 5, px: 3 }}
            onClick={() => {
              onClickEditAccountStatus(1);
            }}
          >
            有効化する
          </Button>
        </>
      ) : null}
    </Box>
  );
};
