import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { Template } from '../components/templates/Template';
import { useCheckToken } from '../hooks/useCheckToken';
import { useAddUser } from '../hooks/useAddUser';
import { AddEditUserForm, ReqAddEditUser } from '../types/api/Users';
import { useOneUser } from '../hooks/useOneUser';
import { useEditUser } from '../hooks/useEditUser';

const types = [
  { id: 1, name: 'ダッシュボードユーザー' },
  { id: 2, name: 'システムユーザー' },
];

// バリデーションルール
const schema = yup.object({
  name: yup.string().required('必須項目です'),
});

type Props = RouteComponentProps<{
  account_id: string;
  id: string;
}>;

// React Component
export const UserEditPage: React.VFC<Props> = (props) => {
  // checkToken -----------------------------
  const { checkToken } = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  // ----------------------------------------

  const { account_id, id } = props.match.params;
  const history = useHistory();
  const { editUser } = useEditUser();
  const { getUser, user } = useOneUser();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<AddEditUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 1,
      name: '',
      email: '',
      password: '',
      department: '',
      phone: '',
    },
  });

  const toAccountDetail = () => {
    history.push(`/accounts/details/${account_id}?tab=1`);
  };

  const registerAccount: SubmitHandler<AddEditUserForm> = (data) => {
    console.log(data);
    const req: ReqAddEditUser = {
      account_id: account_id,
      type: data.type,
      name: data.name,
      email: data.email,
      password: data.password,
      department: data.department,
      phone: data.phone,
    };
    console.log(req);
    editUser(id, req);
  };

  useEffect(() => {
    getUser(id);
  }, []);
  useEffect(() => {
    setValue('type', user.type);
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('password', user.password);
    setValue('department', user.department);
    setValue('phone', user.phone);
  }, [user]);

  return (
    <Template title={'ユーザー登録'} menuId={0}>
      <Box maxWidth="sm" sx={{ py: 5, px: 3 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="ユーザー名"
                {...field}
                error={'name' in errors}
                helperText={errors.name?.message}
                size="small"
              />
            )}
            control={control}
            name="name"
            defaultValue={user.name}
          />
          {/* <FormControl fullWidth size="small" required error={'type' in errors}>
            <InputLabel id="demo-simple-select-label">ユーザータイプ</InputLabel>
            <Select id="demo-simple-select" {...register('type')} label="デバイスタイプ*">
              {types.length > 0
                ? types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errors.type?.message}</FormHelperText>
          </FormControl> */}
          <Controller
            render={({ field }) => (
              <TextField
                required
                label="メールアドレス"
                {...field}
                error={'email' in errors}
                helperText={errors.email?.message}
                size="small"
              />
            )}
            control={control}
            name="email"
            defaultValue={user.email}
          />

          <Controller
            render={({ field }) => (
              <TextField
                required
                label="パスワード"
                {...field}
                error={'password' in errors}
                helperText={errors.password?.message}
                size="small"
              />
            )}
            control={control}
            name="password"
            defaultValue={user.password}
          />

          <Controller
            render={({ field }) => (
              <TextField
                label="部署"
                {...field}
                error={'department' in errors}
                helperText={errors.department?.message}
                size="small"
              />
            )}
            control={control}
            name="department"
            defaultValue={user.department}
          />
          <Controller
            render={({ field }) => (
              <TextField
                label="電話番号"
                {...field}
                error={'phone' in errors}
                helperText={errors.phone?.message}
                size="small"
              />
            )}
            control={control}
            name="phone"
            defaultValue={user.phone}
          />
        </Stack>

        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(registerAccount)}>
            ユーザー登録
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toAccountDetail}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
