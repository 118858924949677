import { useState, useCallback } from 'react';
import axios from 'axios';
import { AccountType } from '../types/api/Accounts';
import { ApiUrl } from '../conf/config';

export const useAllAccounts = () => {
  const [accounts, setAccounts] = useState<Array<AccountType>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccounts = useCallback(() => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/accounts/list`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.accounts !== null) {
          setAccounts(res.data.accounts);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getAccounts, accounts, loading, error };
};
