import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResGetOneGroup } from '../types/api/Groups';
import { ApiUrl } from '../conf/config';

export const useOneGroup = (id: string) => {
  const [group, setGroup] = useState<ResGetOneGroup>({
    id: '',
    account_id: '',
    account_name: '',
    name: '',
    memo: '',
    devices: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getGroup = useCallback(() => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/groups/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        console.log(res.data);
        setGroup(res.data);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getGroup, group, loading, error };
};
