import { useState, useCallback } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ResUser } from '../types/api/Users';

export const useAccountUsers = () => {
  const [users, setUsers] = useState<Array<ResUser>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [notExists, setNotExists] = useState(false);

  const getAccountUsers = useCallback(async (account_id) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.get(`${ApiUrl}/admin/api/v1/users/account/${account_id}`, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200 && res.data.users !== null) {
        setUsers(res.data.users);
      } else if (res.data.users === null) {
        setNotExists(true);
      }
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { getAccountUsers, users, notExists, loading, error };
};
