import { useState, useCallback } from 'react';
import axios from 'axios';
import { ResDeviceListType } from '../types/api/Devices';
import { ApiUrl } from '../conf/config';

export const useAllDevices = () => {
  const [devices, setDevices] = useState<Array<ResDeviceListType>>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getDevices = useCallback(() => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    axios
      .get(`${ApiUrl}/admin/api/v1/devices/list`, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => {
        if (res.data.devices === null) {
          setDevices([]);
        } else {
          setDevices(res.data.devices);
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { getDevices, devices, loading, error };
};
