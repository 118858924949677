import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { ReqAddEditUser } from '../types/api/Users';

export const useEditUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const editUser = useCallback(async (id: string, user: ReqAddEditUser) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.put(`${ApiUrl}/admin/api/v1/users/${id}`, user, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200 && res.data.account_id !== '') {
        alert('ユーザー情報を保存しました。');
        history.push(`/accounts/details/${res.data.account_id}?tab=1`);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { editUser, loading, error };
};
