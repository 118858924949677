import { useState, useCallback } from 'react';
import axios from 'axios';
import { ApiUrl } from '../conf/config';
import { AccountType } from '../types/api/Accounts';

export const useOneAccount = () => {
  const [account, setAccount] = useState<AccountType>({
    id: '',
    name: '',
    status: 99,
    face_manage_type: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccount = useCallback(async (id: string) => {
    setError(false);
    setLoading(true);

    const token = sessionStorage.getItem('token');

    try {
      const res = await axios.get(`${ApiUrl}/admin/api/v1/accounts/${id}`, {
        headers: { Authorization: 'Bearer ' + token },
      });
      if (res.status === 200 && res.data !== null) {
        setAccount(res.data);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return { getAccount, account, loading, error };
};
