import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material UI components
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// custom
import { Template } from '../components/templates/Template';
import { useAllAccounts } from '../hooks/useAllAccounts';
import { useAccountDevices } from '../hooks/useAccountDevices';
import { useAddGroup } from '../hooks/useAddGroup';
import { ReqAddGroup } from '../types/api/Groups';
import { ResAccountDevicesType } from '../types/api/Devices';
import { useCheckToken } from '../hooks/useCheckToken';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// バリデーションルール
const schema = yup.object({
  account_id: yup.string().required('選択してください'),
  name: yup.string().required('必須項目です'),
});

// React Component
export const GroupRegister: React.VFC = () => {
  const history = useHistory();
  const [deviceList, setDeviceList] = useState<Array<ResAccountDevicesType>>([]);

  // react hooks form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReqAddGroup>({
    resolver: yupResolver(schema),
  });

  // custom hooks
  const { getAccounts, accounts, loading: accountLoading, error: accountError } = useAllAccounts();
  const { getAccountDevices, devices, notExists, loading: deviceLoading, error: deviceError } = useAccountDevices();
  const { addGroup, loading, error } = useAddGroup();
  const { checkToken } = useCheckToken();

  useEffect(() => {
    checkToken();
  }, []);

  // デバイス一覧ページへ
  const toGroupIndex = () => {
    history.push('/groups');
  };

  // デバイス登録
  const sendGroup: SubmitHandler<ReqAddGroup> = (data) => {
    let reqDeviceIds: Array<string> = [];
    for (let v of deviceList) {
      reqDeviceIds = [...reqDeviceIds, v.id];
    }
    data.device_ids = reqDeviceIds;
    addGroup(data);
    if (!error && !loading) {
      alert('グループを登録しました。');
      toGroupIndex();
    }
  };

  // 選択したアカウントのデバイスを取得
  const onChangeAccount = (event: any) => {
    getAccountDevices(event.target.value);
  };

  // API
  React.useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <Template title="グループ作成" menuId={2}>
      <Box maxWidth="sm" sx={{ py: 5 }}>
        <Stack spacing={2} sx={{ mb: 5 }}>
          <FormControl fullWidth size="small" required error={'account_id' in errors}>
            <InputLabel id="demo-simple-select-label">アカウント</InputLabel>
            <Select id="demo-simple-select" {...register('account_id')} label="アカウント*" onChange={onChangeAccount}>
              {accounts.length > 0
                ? accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText>{errors.account_id?.message}</FormHelperText>
          </FormControl>
          <TextField
            required
            label="グループ名"
            {...register('name')}
            error={'name' in errors}
            helperText={errors.name?.message}
            size="small"
          />
          <TextField
            label="メモ"
            {...register('memo')}
            error={'memo' in errors}
            helperText={errors.memo?.message}
            size="small"
          />
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            onChange={(event, newValue) => {
              setDeviceList([...newValue]);
            }}
            options={devices}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            style={{ width: '100%' }}
            renderInput={(params) => (
              <TextField {...params} label="デバイス" placeholder="デバイスを選択" size="small" />
            )}
          />
        </Stack>
        <div dir="rtl">
          <Button variant="contained" sx={{ my: 2, ml: 2 }} onClick={handleSubmit(sendGroup)}>
            作成
          </Button>
          <Button variant="contained" sx={{ my: 2 }} color="secondary" onClick={toGroupIndex}>
            キャンセル
          </Button>
        </div>
      </Box>
    </Template>
  );
};
